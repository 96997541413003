<template>
  <v-form class="fill-height" @submit.prevent v-if="obBranch">
    <gw-form-observer @save="save" @cancel="cancel" :loading="isLoading">
      <simple-fields v-if="isCustomer" v-model="obBranch" />
      <template v-else>
        <v-tabs right v-model="tab">
          <v-tab v-for="(item, index) in tabItems" :key="`tab-item-${index}`">
            {{ $t(slugify(item)) }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(item, index) in tabItems"
            :key="`tab-content-${index}`"
          >
            <component :is="item" v-model="obBranch" v-bind="getProps(item)" />
          </v-tab-item>
        </v-tabs-items>
      </template>

      <template #bottom-actions>
        <v-btn
          color="red"
          text
          icon
          @click="remove"
          v-if="obBranch.id && !obBranch.is_default"
        >
          <icon-trash size="1.5em" />
        </v-btn>
      </template>
    </gw-form-observer>
  </v-form>
</template>

<script lang="ts">
import { Branch, BranchData } from "@planetadeleste/vue-mc-gw";
import {
  Mixins,
  Component,
  Prop,
  VModel,
  Inject,
} from "vue-property-decorator";
import GlobalMixin from "@/mixins/GlobalMixin";
import FormTabSettings from "@/modules/companies/components/branchtabs/Settings.vue";
import FormTabLocation from "@/modules/companies/components/branchtabs/Location.vue";
import FormTabDgi from "@/modules/companies/components/branchtabs/Dgi.vue";
import { AppModule } from "@/store/app";
import { Ref } from "@/types/utils";
import SimpleFields from "@/modules/companies/components/branchtabs/SimpleFields.vue";
import { assign } from "lodash";

@Component({
  components: { SimpleFields, FormTabSettings, FormTabLocation, FormTabDgi },
})
export default class BranchForm extends Mixins(GlobalMixin) {
  tab = 0;
  tabItems: string[] = ["form-tab-settings", "form-tab-dgi"];

  @VModel({ type: Object, default: () => ({}) }) obBranch!: BranchData;
  @Prop(Number) readonly companyId!: number;
  @Prop(Boolean) readonly secondary!: boolean;
  @Prop(Boolean) readonly isBranch!: boolean;

  @Inject() isCustomerRef!: Ref<boolean>;

  get isCustomer() {
    return this.isCustomerRef?.value;
  }

  get company() {
    return AppModule.company;
  }

  async save() {
    if (!this.isCustomer) {
      const iCompanyID = this.companyId || this.$route.params.id;
      if (!iCompanyID && !this.obBranch.company_id) {
        return;
      } else if (iCompanyID) {
        this.obBranch.company_id = Number(iCompanyID);
      }
    } else {
      const iCustomerID = this.$route.params.id;
      this.obBranch.customer_id = Number(iCustomerID);
      this.obBranch.company_id = this.company.id;
    }

    this.loading();

    const obBranch =
      this.obBranch instanceof Branch
        ? this.obBranch
        : new Branch(this.obBranch);

    if (this.isCustomer) {
      obBranch.set("customer_id", this.obBranch.customer_id);
    }

    const obResponse = await obBranch
      .store()
      .then((response) => response?.getData())
      .catch((reason) => {
        this.evalResponse(reason);
      });

    if (obResponse && obResponse.status) {
      this.$emit("saved");
    }

    this.loaded();
  }

  async remove() {
    const sMessage = this.$t("ask.remove.record") as string;
    const bRes = await this.$confirm(sMessage, { color: "warning" });
    if (bRes) {
      const obBranch =
        this.obBranch instanceof Branch
          ? this.obBranch
          : new Branch(this.obBranch);

      this.loading();

      const obResponse = await obBranch.delete();
      this.evalResponse(obResponse?.getData());

      this.loaded();
      this.$emit("deleted");
    }
  }

  cancel() {
    this.$emit("cancel");
  }

  getProps(sComp: string): Record<string, any> {
    const obProps = {};

    switch (sComp) {
      case "form-tab-settings":
        assign(obProps, { secondary: this.secondary });
        break;

      case "form-tab-dgi":
        assign(obProps, { isBranch: this.isBranch });
        break;

      default:
        break;
    }

    return obProps;
  }
}
</script>
