<template>
  <v-row>
    <firm-form
      v-model="obFirm"
      location
      hide-basics
      hide-description
      end-customer
    />
  </v-row>
</template>

<script lang="ts">
import { Branch, FirmData } from "@planetadeleste/vue-mc-gw";
import { Vue, Component, VModel } from "vue-property-decorator";
import FirmForm from "@/modules/companies/components/FirmForm.vue";
import { LocationModule } from "@/store/location";

@Component({
  components: { FirmForm },
})
export default class BranchLocation extends Vue {
  @VModel({ type: Object, default: () => ({}) }) obBranch!: Branch;

  mounted() {
    this.$nextTick(() => {
      if (!this.obFirm.country_id && this.obCountry) {
        this.obFirm.country_id = this.obCountry.id;
      }
    });
  }

  get obFirm() {
    return this.obBranch.firm || {};
  }

  set obFirm(obData: Partial<FirmData>) {
    this.obBranch.set("firm", obData);
  }

  get obCountry() {
    return LocationModule.defaultCountry;
  }
}
</script>
